<template>
  <div class="storeMapNav" style="text-align: left;">
    <van-nav-bar
        title="门店导航"
        left-text="返回"
        left-arrow
        @click-left="onReturn"
    />
    <div class="map-container" id="container">
      <iframe v-show="iframeState" id="show-iframe" frameborder=0 name="showHere" scrolling=auto :src="iframeSrc"></iframe>
    </div>
  </div>
</template>

<script>

// https://lbs.qq.com/webApi/uriV1/uriGuide/uriWebRoute
// https://lbs.amap.com/api/javascript-api/guide/services/navigation#get_data
// https://lbs.amap.com/api/webservice/guide/api/georegeo
export default {
  name: "StoreMapNav",
  components: {},
  data() {
    return {
      iframeState:false,
      iframeSrc: '',
    };
  },
  mounted(){
    const oIframe = document.getElementById('show-iframe');
    const deviceWidth = document.documentElement.clientWidth;
    const deviceHeight = document.documentElement.clientHeight;
    oIframe.style.width = deviceWidth + 'px';
    oIframe.style.height = deviceHeight + 'px';
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      let address = this.$route.query.address;
      if(!address) {
        address = '南京市苏宁总部';
      }
      // document.getElementById('show-iframe').src = 'https://apis.map.qq.com/uri/v1/routeplan?type=drive&to=' + address + '&policy=0&referer=sn-csdlot';
      this.iframeSrc = 'https://apis.map.qq.com/uri/v1/routeplan?type=drive&to=' + address + '&policy=0&referer=sn-csdlot';
      this.iframeState = true;
    },
    onReturn() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="scss" scoped>
#container {
  position: absolute;
  top:50px;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
